import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pipe,
  Pump,
  Pump2,
  Pond,
  ValveConnect,
  PipePlaceholder,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowSmall,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  MultiInfo,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  // MobileStatTable,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  VFDPumpControl,
  DrainValveControl,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
// import valveImg from 'isno/lib/static/images/Valve/Valve.png';
import valveImgGreen from 'isno/lib/static/images/Valve/Valve_Open.png';
import valveImgRed from 'isno/lib/static/images/Valve/Valve_Closed.png';
// import { EquipmentPropType } from 'isno/lib/components/prop-types/Equipment';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Valves
  loonPeakChargeValve: 'loon_peak_charge_valve_open_close',
  realEstateChargeValve: 'real_estate_charge_valve_open_close',
  southPeakChargeValve: 'south_peak_charge_valve_open_close',
  loonPeakMainValve: 'loon_peak_main_valve_open_close',
  southPeakMainValve: 'south_peak_main_valve_open_close',
  cv1Position: 'cv1_position',
  cv2Position: 'cv2_position',
  cv3Position: 'cv3_position',
  cv4Position: 'cv4_position',
  cv5Position: 'cv5_position',
  dischargeWaterPressure: 'discharge_water_pressure',

  // Pumps
  amps: 'current',
  hours: 'run_time',
};

function NewRiverScreen(props) {
  const theme = useTheme();
  const language = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });
  const [totalElectronicWaterFlow, setTotalElectronicWaterFlow] = useState(0);
  const [totalMechanicalWaterFlow, setTotalMechanicalWaterFlow] = useState(0);
  const [realEstateDischargeWaterPressure, setRealEstateDischargeWaterPressure] = useState(0);
  const [loonPeakDischargeWaterPressure, setLoonPeakDischargeWaterPressure] = useState(0);
  const [loonElectronicWaterFlow, setLoonElectronicWaterFlow] = useState(0);
  const [loonMechanicalWaterFlow, setLoonMechanicalWaterFlow] = useState(0);
  const [southPeakDischargeWaterPressure, setSouthPeakDischargeWaterPressure] = useState(0);
  const [southElectronicWaterFlow, setSouthElectronicWaterFlow] = useState(0);
  const [southMechanicalWaterFlow, setSouthMechanicalWaterFlow] = useState(0);

  useEffect(() => {
    props.fetchLocation(12); // (3)
    props.fetchLocation(8); // (7)
    props.fetchPLCs();
    const interval = setInterval(() => {
      props.fetchLocation(12); // (3)
      props.fetchLocation(8); // (7)
      props.fetchPLCs();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const locationEquipment3 = props.locations?.locations?.[12]?.equipment; // (3)
  const locationEquipment7 = props.locations?.locations?.[8]?.equipment; // (7)

  const pump11Status = locationEquipment7?.P11?.status;
  const pump12Status = locationEquipment7?.P12?.status;
  const pump13Status = locationEquipment7?.P13?.status;
  const pump14Status = locationEquipment7?.P14?.status;
  const pump15Status = locationEquipment7?.P15?.status;

  const numberOfPumpsOn = [pump11Status, pump12Status, pump13Status, pump14Status, pump15Status].reduce((a, b) => (a + b), 0);

  // useEffect specifically for the demo to set loonPeakDischargeWaterPressure, dischargeWaterPressure, backWaterFlow, diversionFlow, and LPDV/SPDV drain states according to the current state of the pumps
  useEffect(() => {
    if (numberOfPumpsOn > 0
        && locationEquipment7?.LPDV?.ioValues?.close_open?.value === 0
        && locationEquipment7?.['CV System PID']?.ioValues?.manual_setpoint?.value > 0
        && (locationEquipment7?.Other2?.ioValues?.south_peak_electronic_discharge_water_flow?.value === 0
          || (locationEquipment7?.['CV5 PID']?.ioValues?.manual_setpoint?.value > 0 && locationEquipment7?.Other2?.ioValues?.loon_peak_discharge_water_pressure?.value === 0)
          || (locationEquipment7?.Other2?.ioValues?.loon_peak_discharge_water_pressure?.value === 0 && locationEquipment7?.Other2?.ioValues?.real_estate_discharge_water_pressure?.value === 0 && locationEquipment7?.Other2?.ioValues?.south_peak_mechanical_discharge_water_flow?.value === 0))) {
      setLoonPeakDischargeWaterPressure(939);
      setLoonElectronicWaterFlow(125 * numberOfPumpsOn * 7);
      setLoonMechanicalWaterFlow(150 * numberOfPumpsOn * 7);
    } else {
      setLoonPeakDischargeWaterPressure(0);
      setLoonElectronicWaterFlow(0);
      setLoonMechanicalWaterFlow(0);
    }
  }, [numberOfPumpsOn,
    locationEquipment7?.LPDV?.ioValues?.close_open?.value,
    locationEquipment7?.['CV System PID']?.ioValues?.manual_setpoint?.value,
    locationEquipment7?.Other2?.ioValues?.south_peak_electronic_discharge_water_flow?.value,
    locationEquipment7?.Other2?.ioValues?.south_peak_mechanical_discharge_water_flow?.value,
    locationEquipment7?.['CV5 PID']?.ioValues?.manual_setpoint?.value,
    locationEquipment7?.Other2?.ioValues?.loon_peak_discharge_water_pressure?.value,
    locationEquipment7?.Other2?.ioValues?.real_estate_discharge_water_pressure?.value,
  ]);

  useEffect(() => {
    if (numberOfPumpsOn > 0
        && locationEquipment7?.SPDV?.ioValues?.close_open?.value === 0
        && locationEquipment7?.['CV System PID']?.ioValues?.manual_setpoint?.value > 0
        && (locationEquipment7?.Other2?.ioValues?.south_peak_mechanical_discharge_water_flow?.value === 0
          || (locationEquipment7?.['CV5 PID']?.ioValues?.manual_setpoint?.value > 0 && locationEquipment7?.Other2?.ioValues?.real_estate_discharge_water_pressure?.value === 0)
          || (locationEquipment7?.Other2?.ioValues?.loon_peak_discharge_water_pressure?.value === 0 && locationEquipment7?.Other2?.ioValues?.real_estate_discharge_water_pressure?.value === 0 && locationEquipment7?.Other2?.ioValues?.south_peak_electronic_discharge_water_flow?.value === 0))) {
      setSouthPeakDischargeWaterPressure(943);
      setSouthElectronicWaterFlow(127 * numberOfPumpsOn * 7);
      setSouthMechanicalWaterFlow(233 * numberOfPumpsOn * 7);
    } else {
      setSouthPeakDischargeWaterPressure(0);
      setSouthElectronicWaterFlow(0);
      setSouthMechanicalWaterFlow(0);
    }
  }, [numberOfPumpsOn,
    locationEquipment7?.SPDV?.ioValues?.close_open?.value,
    locationEquipment7?.['CV System PID']?.ioValues?.manual_setpoint?.value,
    locationEquipment7?.Other2?.ioValues?.south_peak_electronic_discharge_water_flow?.value,
    locationEquipment7?.Other2?.ioValues?.south_peak_mechanical_discharge_water_flow?.value,
    locationEquipment7?.['CV5 PID']?.ioValues?.manual_setpoint?.value,
    locationEquipment7?.Other2?.ioValues?.loon_peak_discharge_water_pressure?.value,
    locationEquipment7?.Other2?.ioValues?.real_estate_discharge_water_pressure?.value,
  ]);

  useEffect(() => {
    if (numberOfPumpsOn > 0
        && locationEquipment7?.['CV System PID']?.ioValues?.manual_setpoint?.value > 0
        && ((locationEquipment7?.['CV5 PID']?.ioValues?.manual_setpoint?.value > 0 && locationEquipment7?.Other2?.ioValues?.south_peak_discharge_water_pressure?.value === 0)
          || (locationEquipment7?.Other2?.ioValues?.south_peak_mechanical_discharge_water_flow?.value === 0 && locationEquipment7?.Other2?.ioValues?.real_estate_discharge_water_pressure?.value === 0 && locationEquipment7?.Other2?.ioValues?.south_peak_discharge_water_pressure?.value === 0)
          || (locationEquipment7?.Other2?.ioValues?.south_peak_electronic_discharge_water_flow?.value === 0 && locationEquipment7?.Other2?.ioValues?.loon_peak_discharge_water_pressure?.value === 0 && locationEquipment7?.Other2?.ioValues?.south_peak_discharge_water_pressure?.value === 0))) {
      setRealEstateDischargeWaterPressure(713);
    } else {
      setRealEstateDischargeWaterPressure(0);
    }
  }, [numberOfPumpsOn,
    locationEquipment7?.SPDV?.ioValues?.close_open?.value,
    locationEquipment7?.['CV System PID']?.ioValues?.manual_setpoint?.value,
    locationEquipment7?.Other2?.ioValues?.south_peak_electronic_discharge_water_flow?.value,
    locationEquipment7?.Other2?.ioValues?.south_peak_mechanical_discharge_water_flow?.value,
    locationEquipment7?.['CV5 PID']?.ioValues?.manual_setpoint?.value,
    locationEquipment7?.Other2?.ioValues?.loon_peak_discharge_water_pressure?.value,
    locationEquipment7?.Other2?.ioValues?.real_estate_discharge_water_pressure?.value,
    locationEquipment7?.Other2?.ioValues?.south_peak_discharge_water_pressure?.value,
  ]);

  useEffect(() => {
    setTotalElectronicWaterFlow(loonElectronicWaterFlow + southElectronicWaterFlow);
  }, [southElectronicWaterFlow, loonElectronicWaterFlow]);

  useEffect(() => {
    setTotalMechanicalWaterFlow(loonMechanicalWaterFlow + southMechanicalWaterFlow);
  }, [southMechanicalWaterFlow, loonMechanicalWaterFlow]);

  useEffect(() => {
    if (numberOfPumpsOn > 0 && locationEquipment7?.['CV System PID']?.ioValues?.manual_setpoint?.value > 0) {
      props.setIOValue(locationEquipment7?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.id, 943);
    } else {
      props.setIOValue(locationEquipment7?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.id, 0);
    }
  }, [numberOfPumpsOn, locationEquipment7?.['CV System PID']?.ioValues?.manual_setpoint?.value]);

  useEffect(() => {
    if (locationEquipment7?.Other?.ioValues?.back_water_pressure?.value === 0 && numberOfPumpsOn > 0) {
      props.setIOValue(locationEquipment7?.Other?.ioValues?.back_water_pressure?.id, 1195);
    } else if (locationEquipment7?.Other?.ioValues?.back_water_pressure?.value > 0 && numberOfPumpsOn === 0) {
      props.setIOValue(locationEquipment7?.Other?.ioValues?.back_water_pressure?.id, 0);
    }
  }, [numberOfPumpsOn]);

  useEffect(() => {
    // let LPDVCloseOpenState = locationEquipment7?.LPDV?.ioValues?.close_open?.value;
    // let SPDVCloseOpenState = locationEquipment7?.SPDV?.ioValues?.close_open?.value;
    if (locationEquipment7?.LPDV?.ioValues?.auto_manual?.value === 0) {
      if (numberOfPumpsOn > 0) {
      // LPDVCloseOpenState = 0;
        props.setIOValue(locationEquipment7?.LPDV?.ioValues?.close_open?.id, 0);
      } else {
      // LPDVCloseOpenState = 1;
        props.setIOValue(locationEquipment7?.LPDV?.ioValues?.close_open?.id, 1);
      }
    }
  }, [numberOfPumpsOn, locationEquipment7?.LPDV?.ioValues?.auto_manual?.value]);

  useEffect(() => {
    if (locationEquipment7?.SPDV?.ioValues?.auto_manual?.value === 0) {
      if (numberOfPumpsOn > 0) {
      // SPDVCloseOpenState = 0;
        props.setIOValue(locationEquipment7?.SPDV?.ioValues?.close_open?.id, 0);
      } else {
      // SPDVCloseOpenState = 1;
        props.setIOValue(locationEquipment7?.SPDV?.ioValues?.close_open?.id, 1);
      }
    }
  }, [numberOfPumpsOn, locationEquipment7?.SPDV?.ioValues?.auto_manual?.value]);

  if (!locationEquipment7 || !locationEquipment3) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer flex="3" border backgroundColor={theme.backgroundColor} label="New River">
            <InfoControlRow>
              <MultiInfo
                title="Total New River Flow"
                subtitles={['Electronic', 'Mechanical']}
                // statistics={[1598 * numberOfPumpsOn]}
                statistics={[
                  totalElectronicWaterFlow,
                  totalMechanicalWaterFlow,
                ]}
                labels={[
                  locationEquipment7.Other2.ioValues.total_electronic_water_flow?.ioValueType?.units,
                  locationEquipment7.Other2.ioValues.total_mechanical_water_flow?.ioValueType?.units,
                ]}
                ids={[
                  locationEquipment7.Other2.ioValues.south_peak_electronic_discharge_water_flow?.id,
                  locationEquipment7.Other2.ioValues.south_peak_mechanical_discharge_water_flow?.id,
                ]}
              />
              {/* <MultiInfo
                title="Diversion Flow"
                subtitles={['Diversion']}
                statistics={[locationEquipment3?.Other?.ioValues?.pemi_river_diversion_water_flow?.value]}
                // statistics={[1598 * numberOfPumpsOn]}
                labels={[locationEquipment3?.Other?.ioValues?.pemi_river_diversion_water_flow?.ioValueType?.units]}
                alertText={(() => {
                  if (locationEquipment3?.Other?.ioValues?.pemi_river_low_flow?.value) {
                    return 'Low Flow';
                  } else if (locationEquipment3?.Other?.ioValues?.pemi_river_low_flow_warning?.value) {
                    return 'Low Flow Warning';
                  } else {
                    return null;
                  }
                })()}
                alertTop="35px"
                alertLeft={locationEquipment3?.Other?.ioValues?.pemi_river_low_flow_warning?.value ? '-12px' : '23px'}
              /> */}
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[7], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[7], theme)}
                alertTop="20px"
                alertLeft="-3px"
                alertText={locationEquipment7?.Other?.ioValues?.plc_low_battery?.value ? 'Low Battery' : null}
              />
              <Info
                title="Instrument Air"
                statistic={locationEquipment7?.IA?.ioValues?.low_air_pressure?.value === 0 ? 'Enabled' : 'Low Pressure'}
                color={locationEquipment7?.IA?.ioValues?.low_air_pressure?.value === 0 ? theme.onGreen : theme.alarm}
                label=""
              />
              <AlarmReset
                title="System reset"
                buttonText="Reset"
                alarmData={locationEquipment7?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: null,
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <VFDPumpControl
                title="CV System PID"
                autoPrecision={1}
                manPrecision={1}
                inputNumWidth="72px"
                pumpData={{ id: `${locationEquipment7?.['CV System PID']?.id}` }}
                pidData={locationEquipment7?.['CV System PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'manual_auto',
                  manualMode: 'manual_auto',
                  autoManual: 'manual_auto',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[1, 0]}
                writeValues={[1, 0]}
              />
              <VFDPumpControl
                title="Real Estate Valve CV5 PID"
                autoPrecision={1}
                manPrecision={1}
                inputNumWidth="64px"
                pumpData={{ id: `${locationEquipment7?.['CV5 PID']?.id}` }}
                pidData={locationEquipment7?.['CV5 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
              />
              <VFDPumpControl
                title="Discharge Valve CV4 PID"
                autoPrecision={1}
                manPrecision={1}
                inputNumWidth="72px"
                pumpData={{ id: `${locationEquipment7?.['CV4 PID']?.id}` }}
                pidData={locationEquipment7?.['CV4 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'manual_auto',
                  manualMode: 'manual_auto',
                  autoManual: 'manual_auto',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[1, 0]}
                writeValues={[1, 0]}
              />
              <Info
                img={numberOfPumpsOn > 0 ? valveImgRed : valveImgGreen}
                title="Main Drain"
                // statistic={locationEquipment7?.Other2?.ioValues?.main_drain_valve_open_close?.value ? 'Closed' : 'Open'}
                statistic={numberOfPumpsOn > 0 ? 'Closed' : 'Open'}
                // color={locationEquipment7?.Other2?.ioValues?.main_drain_valve_open_close?.value ? theme.offRed : theme.onGreen}
                color={theme.textColor}
                label=""
              />
              <DrainValveControl
                title="Loon Peak Drain Valve"
                valveData={locationEquipment7.LPDV}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                openIOValueID={locationEquipment7.LPDV?.ioValues?.close_open?.id}
                closeIOValueID={locationEquipment7.LPDV?.ioValues?.close_open?.id}
                setIOValue={props.setIOValue}
              />
              <DrainValveControl
                title="South Peak Drain Valve"
                valveData={locationEquipment7.SPDV}
                openIOValueID={locationEquipment7.SPDV?.ioValues?.close_open?.id}
                closeIOValueID={locationEquipment7.SPDV?.ioValues?.close_open?.id}
                setIOValue={props.setIOValue}
                writeValues={[1, 0]}
                readValues={[1, 0]}
              />
            </InfoControlRow>
            <PumpsRowSmall>
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <div css={css`height: 100%; display: flex; flex: 1; min-width: ${theme.componentMinWidth};`}>
                <div css={styles.flexCol}>
                  <div css={css`height: 25px;`}>
                    <PipePlaceholder
                      capBefore
                    />
                  </div>
                  <div css={[css`width: 51px; display: flex; flex-direction: column; justify-content: center; align-items: center;`, styles.hiddenMobile]}>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        border
                        label={locationEquipment7?.Other2?.ioValues.loon_peak_discharge_water_pressure?.ioValueType?.units}
                        statistic={loonPeakDischargeWaterPressure}
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        statistic={loonElectronicWaterFlow}
                        border
                        label={locationEquipment7?.Other3?.ioValues.loon_peak_electronic_water_flow?.ioValueType?.units}
                        floatLabel="(Electronic)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        statistic={loonMechanicalWaterFlow}
                        border
                        label={locationEquipment7?.Other3?.ioValues.loon_peak_mechanical_water_flow?.ioValueType?.units}
                        floatLabel="(Mechanical)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                  </div>
                  <PipePlaceholder
                    minWidth="45px"
                    pipeBelow="right"
                    connectBelow
                  />
                </div>
                <ValveConnect
                  controls
                  controlsTop="23px"
                  controlsLeft="-27px"
                  horizontal="below"
                  minWidth="147px"
                  valveData={{ ...locationEquipment7?.Other2, name: '' }}
                  valveStateKeys={{
                    0: 'Open',
                    1: 'Closed',
                  }}
                  IOValueKeys={{
                    open: 'loon_peak_discharge_water_pressure',
                    close: 'loon_peak_discharge_water_pressure',
                    opened: 'loon_peak_discharge_water_pressure',
                    closed: 'loon_peak_discharge_water_pressure',
                  }}
                  writeValues={[0, 1]}
                  readValues={[0, 1]}
                  setIOValue={props.setIOValue}
                  redImageKey="Open"
                  greenImageKey="Closed"
                />
                <div css={styles.flexCol}>
                  <div css={css`height: 25px;`}>
                    <PipePlaceholder
                      capBefore
                    />
                  </div>
                  <div css={[css`width: 51px; display: flex; justify-content: center;`, styles.hiddenMobile]}>
                    <Statistic
                      label={locationEquipment7?.Other2?.ioValues.real_estate_discharge_water_pressure?.ioValueType?.units}
                      border
                      statistic={realEstateDischargeWaterPressure}
                    />
                  </div>
                  <ValveConnect
                    controls
                    controlsTop="-50px"
                    controlsLeft="-45px"
                    minWidth="45px"
                    pipeBelow="middle"
                    valveData={{ ...locationEquipment7?.Other2, name: '' }}
                    valveStateKeys={{
                      0: 'Open',
                      1: 'Closed',
                    }}
                    IOValueKeys={{
                      opened: 'south_peak_discharge_water_pressure',
                      closed: 'south_peak_discharge_water_pressure',
                      open: 'south_peak_discharge_water_pressure',
                      close: 'south_peak_discharge_water_pressure',
                    }}
                    writeValues={[0, 1]}
                    readValues={[0, 1]}
                    setIOValue={props.setIOValue}
                    redImageKey="Open"
                    greenImageKey="Closed"
                  />
                </div>
                <ValveConnect
                  controls
                  controlsTop="23px"
                  controlsLeft="-27px"
                  horizontal="below"
                  minWidth="147px"
                  valveData={{ ...locationEquipment7?.Other2, name: '' }}
                  valveStateKeys={{
                    0: 'Open',
                    1: 'Closed',
                  }}
                  IOValueKeys={{
                    opened: 'real_estate_discharge_water_pressure',
                    closed: 'real_estate_discharge_water_pressure',
                    open: 'real_estate_discharge_water_pressure',
                    close: 'real_estate_discharge_water_pressure',
                  }}
                  writeValues={[0, 1]}
                  readValues={[0, 1]}
                  setIOValue={props.setIOValue}
                  redImageKey="Open"
                  greenImageKey="Closed"
                />
                <div css={styles.flexCol}>
                  <div css={css`height: 25px;`}>
                    <PipePlaceholder
                      capBefore
                    />
                  </div>
                  <div css={[css`width: 51px; display: flex; flex-direction: column; justify-content: center; align-items: center;`, styles.hiddenMobile]}>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment7?.Other2?.ioValues.south_peak_discharge_water_pressure?.ioValueType?.units}
                        border
                        statistic={southPeakDischargeWaterPressure}
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment7?.Other2?.ioValues.south_peak_electronic_discharge_water_flow?.ioValueType?.units}
                        border
                        statistic={southElectronicWaterFlow}
                        floatLabel="(Electronic)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment7?.Other2?.ioValues.south_peak_mechanical_discharge_water_flow?.ioValueType?.units}
                        border
                        statistic={southMechanicalWaterFlow}
                        floatLabel="(Mechanical)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                  </div>
                  <PipePlaceholder
                    minWidth="45px"
                    pipeBelow="left"
                    connectBelow
                  />
                </div>
              </div>
              <PipePlaceholder hidden />
            </PumpsRowSmall>
            <PumpsRowCustom height="60px">
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <div css={css`height: 100%; display: flex; flex: 1; min-width: ${theme.componentMinWidth};`}>
                <ValveConnect
                  controls
                  controlsTop="-50px"
                  controlsLeft="-46px"
                  minWidth="45px"
                  nameLeft="-90px"
                  valveData={{ ...locationEquipment7?.Other2, name: 'Loon Peak' }}
                  valveStateKeys={{
                    0: 'Open',
                    1: 'Closed',
                  }}
                  IOValueKeys={{
                    opened: 'south_peak_electronic_discharge_water_flow',
                    closed: 'south_peak_electronic_discharge_water_flow',
                    open: 'south_peak_electronic_discharge_water_flow',
                    close: 'south_peak_electronic_discharge_water_flow',
                  }}
                  writeValues={[0, 1]}
                  readValues={[0, 1]}
                  setIOValue={props.setIOValue}
                  redImageKey="Open"
                  greenImageKey="Closed"
                />
                <PipePlaceholder minWidth="147px" hidden />
                <ValveConnect
                  minWidth="45px"
                  reverseValve
                  percent
                  percentTop="-25px"
                  percentLeft="-25px"
                  nameTop="-15px"
                  nameLeft="25px"
                  valveData={{
                    name: 'Real Estate CV5',
                    id: locationEquipment7?.['CV5 PID']?.id,
                    // state: locationEquipment7?.Other2?.ioValues?.[IOValueKeys.cv5Position]?.value,
                    state: locationEquipment7?.['CV5 PID'].ioValues?.manual_setpoint?.value,

                  }}
                  binary={false}
                />
                <PipePlaceholder minWidth="147px" hidden />
                <ValveConnect
                  controls
                  controlsTop="-50px"
                  controlsLeft="-46px"
                  minWidth="45px"
                  nameLeft="50px"
                  valveData={{ ...locationEquipment7?.Other2, name: 'South Peak' }}
                  valveStateKeys={{
                    0: 'Open',
                    1: 'Closed',
                  }}
                  IOValueKeys={{
                    opened: 'south_peak_mechanical_discharge_water_flow',
                    closed: 'south_peak_mechanical_discharge_water_flow',
                    open: 'south_peak_mechanical_discharge_water_flow',
                    close: 'south_peak_mechanical_discharge_water_flow',
                  }}
                  writeValues={[0, 1]}
                  readValues={[0, 1]}
                  setIOValue={props.setIOValue}
                  redImageKey="Open"
                  greenImageKey="Closed"
                />
              </div>
              <PipePlaceholder hidden />
            </PumpsRowCustom>
            <PumpsRowCustom height="80px">
              <PipePlaceholder hidden />
              <div css={css`height: 100%; display: flex; flex: 1; min-width: ${theme.componentMinWidth};`}>
                <ValveConnect
                  readOnly
                  minWidth="0px"
                  percent
                  percentTop="50px"
                  nameLeft="-70px"
                  pipeAbove="right"
                  reverseValve
                  valveData={{
                  // state: locationEquipment7?.Other?.ioValues?.[IOValueKeys.cv2Position]?.value,
                    state: locationEquipment7?.['CV System PID']?.ioValues?.manual_setpoint?.value,
                    name: 'CV2',
                  }}
                  binary={false}
                />
                <ValveConnect
                  readOnly
                  minWidth="0px"
                  percent
                  percentTop="50px"
                  nameLeft="-70px"
                  pipeAbove="middle"
                  reverseValve
                  valveData={{
                    // state: locationEquipment7?.Other?.ioValues?.[IOValueKeys.cv1Position]?.value,
                    state: locationEquipment7?.['CV System PID']?.ioValues?.manual_setpoint?.value,
                    name: 'CV1',
                  }}
                  binary={false}
                />
                <ValveConnect
                  readOnly
                  minWidth="0px"
                  percent
                  percentTop="50px"
                  nameLeft="-70px"
                  pipeAbove="middle"
                  reverseValve
                  valveData={{
                    // state: locationEquipment7?.Other?.ioValues?.[IOValueKeys.cv3Position]?.value,
                    state: locationEquipment7?.['CV System PID']?.ioValues?.manual_setpoint?.value,
                    name: 'CV3',
                  }}
                  binary={false}
                />
              </div>
              <PipePlaceholder horizontalAbove color={theme.pipeColors.water}>
                <Statistic
                  label={locationEquipment7?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment7?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.value}
                  alertText={locationEquipment7?.Other?.ioValues.high_discharge_pressure?.value ? 'High Discharge Pressure' : null}
                  alertTop="25px"
                  alertLeft="-95px"
                />
              </PipePlaceholder>
              <div css={css`height: 100%; display: flex; flex: 1; min-width: ${theme.componentMinWidth};`}>
                <ValveConnect
                  reverseValve
                  minWidth="0px"
                  nameLeft="-80px"
                  pipeAbove="middle"
                  pipeBelow="right"
                  valveData={locationEquipment7?.LPDV}
                  IOValueKeys={{
                    opened: 'close_open',
                    closed: 'close_open',
                  }}
                  readValues={[1, 0]}
                  valveStateKeys={{
                    0: 'Closed',
                    1: 'Open',
                  }}
                  redImageKey="Closed"
                  greenImageKey="Open"
                />
                <ValveConnect
                  reverseValve
                  minWidth="0px"
                  nameLeft="20px"
                  capAfter
                  capLabel="To Well"
                  capLabelTop="10px"
                  capLabelLeft="-65px"
                  pipeAbove="left"
                  pipeBelow="middle"
                  valveData={locationEquipment7?.SPDV}
                  IOValueKeys={{
                    opened: 'close_open',
                    closed: 'close_open',
                  }}
                  readValues={[1, 0]}
                  valveStateKeys={{
                    0: 'Closed',
                    1: 'Open',
                  }}
                  redImageKey="Closed"
                  greenImageKey="Open"
                />
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="135px">
              {/* <PipePlaceholder hidden minWidth="51px" /> */}
              <div css={css`flex: 1; position: relative; height: 100%; min-width: 115px; width: 175px;`}>
                {/* <div css={css`width: 69vw; height: 100%; position: absolute; display: flex; align-items: center; justify-content: flex-start;`}> */}
                <div css={css`width: calc(100vw - 10px - ${props.navbarClosed ? '16px' : '160px'}); height: 100%; position: absolute; display: flex; align-items: center; justify-content: flex-start;`}>
                  <div css={css`flex: 1; display: flex; min-width: 115px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`flex: 1; display: flex; min-width: 175px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`flex: 1; display: flex; min-width: 175px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`flex: 1; display: flex; min-width: 175px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`flex: 1; display: flex; min-width: 175px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`display: flex; min-width: 35px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`flex: 1; min-width: 175px;`} />
                  <div css={css`flex: 1; min-width: 51px;`} />
                </div>
                <div css={css`width: 175px; height: 100%; position: absolute; display: flex; align-items: center; justify-content: flex-start;`}>
                  <div css={[css`min-width: 0px; width: 175px;`, styles.mobilePumpWrapper]}>
                    <Pump2
                      pipeAbove="none"
                      pipeBelow="none"
                      setIOValue={props.setIOValue}
                      pumpData={locationEquipment7?.CP1}
                      otherData={locationEquipment7?.Other}
                      controlPositionTop="30px"
                      controlPositionLeft="-85px"
                      stateColorKey={{
                        0: `${theme.offRed}`,
                        1: `${theme.onGreen}`,
                        2: `${theme.warning}`,
                        3: `${theme.alarm}`,
                      }}
                      IOValueKeys={{
                        start: 'disable_enable',
                        stop: 'disable_enable',
                      }}
                      writeValues={[1, 0]}
                      alarms={[
                      // props.equipment?.P1?.ioValues[IOValueKeys.warning],
                      // props.equipment?.P1?.ioValues[IOValueKeys.fault],
                      // props.equipment?.P1?.ioValues[IOValueKeys.lowAmps],
                      // props.equipment?.P1?.ioValues[IOValueKeys.masterAlarm],
                      // props.equipment?.Other?.ioValues[IOValueKeys.lowSuctionPressure],
                      ]}
                      stats={[
                        {
                          stat: locationEquipment7?.CP1?.ioValues?.[IOValueKeys.amps]?.value,
                          label: locationEquipment7?.CP1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div css={styles.mobilePumpWrapper} />
              <Pump
                percent
                pipeAbove="right"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment7?.P11}
                otherData={locationEquipment7?.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'current',
                  stop: 'current',
                  dischargePressure: 'back_water_pressure',
                }}
                writeValues={[153, 0]}
                alarms={[]}
                stats={[
                  {
                    stat: locationEquipment7?.P11?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment7?.P11?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment7?.P11?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment7?.P11?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment7?.P12}
                otherData={locationEquipment7?.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'current',
                  stop: 'current',
                }}
                // writeValues={[0, 1]}
                writeValues={[152, 0]}
                alarms={[]}
                stats={[
                  {
                    stat: locationEquipment7?.P12?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment7?.P12?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment7?.P12?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment7?.P12?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment7?.P13}
                otherData={locationEquipment7?.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'current',
                  stop: 'current',
                }}
                // writeValues={[0, 1]}
                writeValues={[154, 0]}
                alarms={[]}
                stats={[
                  {
                    stat: locationEquipment7?.P13?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment7?.P13?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment7?.P13?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment7?.P13?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment7?.P14}
                otherData={locationEquipment7?.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'current',
                  stop: 'current',
                }}
                // writeValues={[0, 1]}
                writeValues={[137, 0]}
                alarms={[]}
                stats={[
                  {
                    stat: locationEquipment7?.P14?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment7?.P14?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment7?.P14?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment7?.P14?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment7?.P15}
                otherData={locationEquipment7?.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'current',
                  stop: 'current',
                }}
                // writeValues={[0, 1]}
                writeValues={[139, 0]}
                alarms={[]}
                stats={[
                  {
                    stat: locationEquipment7?.P15?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment7?.P15?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment7?.P15?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment7?.P15?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <ValveConnect
                reverseValve
                // alignRight
                percent
                minWidth="51px"
                percentTop="50px"
                nameLeft="25px"
                pipeAbove="left"
                pipeSizeAbove="large"
                valveData={{
                  name: 'CV4',
                  id: locationEquipment7?.['CV4 PID']?.id,
                  // state: locationEquipment7?.Other?.ioValues?.[IOValueKeys.cv4Position]?.value,
                  state: locationEquipment7?.['CV4 PID']?.ioValues?.manual_setpoint?.value,
                }}
                binary={false}
              />
            </PumpsRowCustom>
            <PumpsRowCustom height="55px">
              <Pond
                flex="25"
                capBefore
                capBeforeLabel="From Transfer System"
                capBeforeLabelTop="-2px"
                capBeforeLabelLeft="65px"
                capBeforeLabelColor="white"
                capAfter
                capAfterLabel="From Connector Pond"
                capAfterLabelTop="-2px"
                capAfterLabelLeft="-215px"
                capAfterLabelColor="white"
                color={theme.pipeColors.water}
              >
                <Info
                  title="Well Level"
                  statistic={17.8}
                  precision={1}
                  label={locationEquipment3?.Other?.ioValues?.new_river_well_level?.ioValueType?.units}
                  alertText={(() => {
                    if (locationEquipment7?.Other?.ioValues?.low_well_level?.value) {
                      return 'Low Well Level';
                    } else if (locationEquipment7?.Other?.ioValues?.low_well_level_warning?.value) {
                      return 'Low Well Level Warning';
                    } else {
                      return null;
                    }
                  })()}
                  alertTop="50px"
                  alertLeft={locationEquipment7?.Other?.ioValues?.low_well_level_warning?.value ? '-50px' : '-15px'}
                />
              </Pond>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexCol: css`
      display: flex;
      flex-direction: column;
      height: 100%;
      min-width: 45px;
      flex: 1;
    `,
    mobilePumpWrapper: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-top: 60px;
      }
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

NewRiverScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

NewRiverScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(NewRiverScreen);
