import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pump2,
  Pond,
  ValveConnect,
  Pipe,
  PipePlaceholder,
  IRCompressor,
  CoolingTower,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowCustom,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  VFDPumpControl,
  AlarmReset,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
// import { EquipmentPropType } from 'isno/lib/components/prop-types/Equipment';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'Building_temperature',
  // Water Flow Source
  cityTankMode: 'City_tank_mode',
  // Water Line Data
  waterFlowRate: 'Water_flow_rate',
  suctionWaterPressure: 'Suction_water_pressure',
  line3WaterFlow: 'Line_3_water_flow',
  line2WaterFlow: 'Line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'Line_2_air_flow',
  line2AirTemp: 'Line_2_air_temperature',
  line3AirFlow: 'Line_3_air_flow',
  line3AirTemp: 'Line_3_air_temperature',
  // Pump Data
  dischargePressure: 'Discharge_water_pressure',
  dischargeTemperature: 'Discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  speed: 'speed',
  temperature: 'temperature',
  // Pump Alarms
  warning: 'Warning',
  fault: 'Fault',
  lowAmps: 'Low_amps',
  masterAlarm: 'Master_alarm',
  lowSuctionPressure: 'Low_suction_pressure',
  emergencyStop: 'Emergency_stop',
  // Valve Control
  openClose: 'Open_close',
  dischargeAirFlow: 'discharge_air_flow',
  dischargeAirPressure: 'discharge_air_pressure',
  manualSetpoint: 'manual_setpoint',
  cv1Position: 'cv1_position',
  dv1Position: 'dv1_close_open',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function MaintenanceScreen(props) {
  const theme = useTheme();
  const language = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(10), // 5
        props.fetchLocation(9), // 6
        props.fetchLocation(6), // 9
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(10); // 5
    props.fetchLocation(9); // 6
    props.fetchLocation(6); // 9
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment5 = props.locations?.locations?.[10]?.equipment; // 5
  const locationEquipment6 = props.locations?.locations?.[9]?.equipment; // 6
  const locationEquipment9 = props.locations?.locations?.[6]?.equipment; // 9

  if (!locationEquipment5 || !locationEquipment6 || !locationEquipment9) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }
  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" border backgroundColor={theme.backgroundColor} label="Old River Compressors">
            <InfoControlRow>
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[8], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[8], theme)}
              // alertText={plcAlertText5}
                alertText={(() => {
                  let plcAlertText5 = 'PLC: ';
                  if (locationEquipment5?.Other?.ioValues?.plc_terminal_mode_off?.value) {
                    plcAlertText5 = ' Terminal Mode Off |';
                  }
                  if (locationEquipment5?.Other?.ioValues?.plc_low_battery?.value) {
                    plcAlertText5 += ' Low Battery';
                  }
                  return plcAlertText5 === 'PLC: ' ? null : plcAlertText5;
                })()}
                alertTop="-35px"
                alertLeft="260px"
              />
              <Info
                title="Instrument Air"
                statistic={locationEquipment6?.IA?.status ? 'Enabled' : 'Disabled'}
                color={locationEquipment6?.IA?.status ? theme.onGreen : theme.alarm}
                alertText={locationEquipment6?.IA?.ioValues?.low_air_pressure?.value ? 'Low Air Pressure' : null}
                label=""
              />
              <AlarmReset
                title="System reset"
                buttonText="Reset"
                alarmData={locationEquipment6?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: null,
                  masterAlarmReset: 'alarm_reset',
                }}
              />
            </InfoControlRow>
            <PumpsRowCustom height="150px">
              <IRCompressor
                location={props.locations?.locations?.[10]?.name}
                compressorData={locationEquipment5?.C6}
                pipeAbove="right"
                pipeTypeAbove={[false, true, false]}
                pipeTypeBelow={[true, true, true]}
                pipeColorCenter={theme.pipeColors.hotWater}
                capBefore
                capLabel="To Hill"
                capLabelTop="10px"
                capLabelLeft="-65px"
                pipeColorAbove={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  compressorStartCommand: 'compressor_start_command',
                  compressorStopCommand: 'compressor_stop_command',
                  compressorModulate: 'compressor_modulate',
                  compressorModulateCommand: 'compressor_modulate_command',
                  compressorUnloaded: 'compressor_unloaded',
                  compressorUnloadCommand: 'compressor_unload_command',
                  compressorResetCommand: 'compressor_reset_command',
                  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: 'stage1_temperature',
                  stage1Pressure: 'stage1_pressure',
                  stage1Vibration: 'stage1_vibration',
                  stage2Temperature: 'stage2_temperature',
                  stage2Pressure: 'stage2_pressure',
                  stage2Vibration: 'stage2_vibration',
                  stage3Temperature: 'stage3_temperature',
                  stage3Pressure: 'stage3_pressure',
                  stage3Vibration: 'stage3_vibration',
                }}
                stats={[
                  {
                    stat: locationEquipment5?.C6?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment5?.C6?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.C6?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment5?.C6?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment5?.C6?.ioValues?.oil_temperature?.value,
                    locationEquipment5?.C6?.ioValues?.oil_pressure?.value,
                    locationEquipment5?.C6?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment5?.C6?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment5?.C6?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment5?.C6?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment5?.C6?.ioValues?.oil_temperature?.id,
                    locationEquipment5?.C6?.ioValues?.oil_pressure?.id,
                    locationEquipment5?.C6?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment5?.C6?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment5?.C6?.ioValues?.system_pressure?.value,
                    locationEquipment5?.C6?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment5?.C6?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment5?.C6?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment5?.C6?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment5?.C6?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment5?.C6?.ioValues?.system_pressure?.id,
                    locationEquipment5?.C6?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              >
                <Statistic
                  float
                  floatColor={theme.pipeColors.air}
                  label={locationEquipment5?.Other?.ioValues[IOValueKeys.dischargeAirPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment5?.Other?.ioValues[IOValueKeys.dischargeAirPressure]?.value}
                />
                <Pipe color={theme.pipeColors.air} horizontal>
                  <Statistic
                    float
                    floatColor={theme.pipeColors.air}
                    label={locationEquipment5?.Other?.ioValues[IOValueKeys.dischargeAirFlow]?.ioValueType?.units}
                    border
                    statistic={locationEquipment5?.Other?.ioValues[IOValueKeys.dischargeAirFlow]?.value}
                  />
                </Pipe>
              </IRCompressor>
              <ValveConnect
                horizontal="center"
                pipeColorAbove={theme.pipeColors.hotWater}
                pipeColorBelow={theme.pipeColors.hotWater}
                percent
                percentTop="25px"
                percentLeft="4px"
                nameTop="-65px"
                nameLeft="7px"
                valveData={{ id: locationEquipment5?.['Cooling System PID'].id, state: locationEquipment5?.['Cooling System PID']?.ioValues[IOValueKeys.manualSetpoint]?.value, name: 'CV1' }}
                binary={false}
              />
              <CoolingTower
                data={locationEquipment5?.CT1}
              // pipeAbove="none"
                pipeTypeAbove={[false, false, false]}
                pipeTypeBelow={[true, false, true]}
                setIOValue={props.setIOValue}
                stateKey={{
                  0: 'Off',
                  1: 'On',
                }}
                stateColorKey={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
                alarms={[
                  locationEquipment5?.CT1?.ioValues?.low_temperature,
                ]}
                stats={[
                  {
                    stat: locationEquipment5?.CT1?.ioValues?.[IOValueKeys.speed]?.value,
                    label: locationEquipment5?.CT1?.ioValues?.[IOValueKeys.speed]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.CT1?.ioValues?.[IOValueKeys.temperature]?.value,
                    label: locationEquipment5?.CT1?.ioValues?.[IOValueKeys.temperature]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.CT1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment5?.CT1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
            </PumpsRowCustom>
            <PumpsRowCustom height="130px">
              <IRCompressor
                location={props.locations?.locations?.[10]?.name}
                compressorData={locationEquipment5?.C7}
              // pipeColorBelow={theme.pipeColors.water}
                pipeTypeAbove={[true, true, true]}
                pipeTypeBelow={[true, false, false]}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  compressorStartCommand: 'compressor_start_command',
                  compressorStopCommand: 'compressor_stop_command',
                  compressorModulate: 'compressor_modulate',
                  compressorModulateCommand: 'compressor_modulate_command',
                  compressorUnloaded: 'compressor_unloaded',
                  compressorUnloadCommand: 'compressor_unload_command',
                  compressorResetCommand: 'compressor_reset_command',
                  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: 'stage1_temperature',
                  stage1Pressure: 'stage1_pressure',
                  stage1Vibration: 'stage1_vibration',
                  stage2Temperature: 'stage2_temperature',
                  stage2Pressure: 'stage2_pressure',
                  stage2Vibration: 'stage2_vibration',
                  stage3Temperature: 'stage3_temperature',
                  stage3Pressure: 'stage3_pressure',
                  stage3Vibration: 'stage3_vibration',
                }}
                stats={[
                  {
                    stat: locationEquipment5?.C7?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment5?.C7?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.C7?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment5?.C7?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment5?.C7?.ioValues?.oil_temperature?.value,
                    locationEquipment5?.C7?.ioValues?.oil_pressure?.value,
                    locationEquipment5?.C7?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment5?.C7?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment5?.C7?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment5?.C7?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment5?.C7?.ioValues?.oil_temperature?.id,
                    locationEquipment5?.C7?.ioValues?.oil_pressure?.id,
                    locationEquipment5?.C7?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment5?.C7?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment5?.C7?.ioValues?.system_pressure?.value,
                    locationEquipment5?.C7?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment5?.C7?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment5?.C7?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment5?.C7?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment5?.C7?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment5?.C7?.ioValues?.system_pressure?.id,
                    locationEquipment5?.C7?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <PipePlaceholder hidden />
              <CoolingTower
                data={locationEquipment5?.CT2}
              // pipeAbove="none"
                pipeTypeAbove={[true, false, true]}
                pipeTypeBelow={[true, false, false]}
                setIOValue={props.setIOValue}
                stateKey={{
                  0: 'Off',
                  1: 'On',
                }}
                stateColorKey={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
                alarms={[
                  locationEquipment5?.CT2?.ioValues?.low_temperature,
                ]}
                stats={[
                  {
                    stat: locationEquipment5?.CT2?.ioValues?.[IOValueKeys.speed]?.value,
                    label: locationEquipment5?.CT2?.ioValues?.[IOValueKeys.speed]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.CT2?.ioValues?.[IOValueKeys.temperature]?.value,
                    label: locationEquipment5?.CT2?.ioValues?.[IOValueKeys.temperature]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.CT2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment5?.CT2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
            </PumpsRowCustom>
            <PumpsRowCustom height="150px">
              <Pump2
                localRemote
                pipeAbove="right"
                connectAbove
                controlWidth="135px"
                controlPositionTop="-70px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment5?.CP1}
                otherData={locationEquipment5.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  localRemote: 'local_remote',
                  local: 'local_remote',
                  remote: 'local_remote',
                  drain: 'drain',
                  dischargePressure: 'cooling_system_pressure',
                }}
                writeValues={[1, 0]}
                alarms={[
                // locationEquipment5?.CP1?.ioValues[IOValueKeys.warning],
                // locationEquipment5?.CP1?.ioValues[IOValueKeys.fault],
                // locationEquipment5?.CP1?.ioValues[IOValueKeys.lowAmps],
                // locationEquipment5?.CP1?.ioValues[IOValueKeys.masterAlarm],
                // props.equipment?.Other?.ioValues[IOValueKeys.lowSuctionPressure],
                ]}
                stats={[
                  {
                    stat: locationEquipment5?.CP1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment5?.CP1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.CP1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment5?.CP1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump2
                localRemote
                pipeAbove="left"
                controlWidth="135px"
                controlPositionTop="-70px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment5?.CP2}
                otherData={locationEquipment5.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  localRemote: 'local_remote',
                  local: 'local_remote',
                  remote: 'local_remote',
                  drain: 'drain',
                }}
                writeValues={[1, 0]}
                alarms={[
                // props.equipment?.P1?.ioValues[IOValueKeys.warning],
                // props.equipment?.P1?.ioValues[IOValueKeys.fault],
                // props.equipment?.P1?.ioValues[IOValueKeys.lowAmps],
                // props.equipment?.P1?.ioValues[IOValueKeys.masterAlarm],
                // props.equipment?.Other?.ioValues[IOValueKeys.lowSuctionPressure],
                ]}
                stats={[
                  {
                    stat: locationEquipment5?.CP2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment5?.CP2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.CP2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment5?.CP2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder />
            </PumpsRowCustom>
            <PumpsRowCustom height="90px">
              <Pond
                flex="12"
                color={theme.pipeColors.water}
              >
                <VFDPumpControl
                  autoPrecision={1}
                  manPrecision={1}
                  title="Cooling System PID"
                  pumpData={{ id: locationEquipment5?.['Cooling System PID']?.id }}
                  pidData={locationEquipment5?.['Cooling System PID']}
                  setIOValue={props.setIOValue}
                  max="1500"
                  IOValueKeys={{
                    autoMode: 'manual_auto',
                    manualMode: 'manual_auto',
                    autoManual: 'manual_auto',
                    autoSetpoint: 'auto_setpoint',
                    manualSetpoint: 'manual_setpoint',
                  }}
                  readValues={[1, 0]}
                  writeValues={[1, 0]}
                />
                {/* <Info
                title="Well Level"
                statistic={0}
                label="Ft"
              /> */}
              </Pond>
            </PumpsRowCustom>
          </LabeledContainer>
          <LabeledContainer height="fit-content" border backgroundColor={theme.backgroundColor} label="Maintenance Center">
            <InfoControlRow>
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[5], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[5], theme)}
              // alertText={plcAlertText9}
                alertText={(() => {
                  let plcAlertText9 = 'PLC: ';
                  if (locationEquipment9?.Other?.ioValues?.plc_terminal_mode_off?.value) {
                    plcAlertText9 = ' Terminal Mode Off |';
                  }
                  if (locationEquipment9?.Other?.ioValues?.plc_low_battery?.value) {
                    plcAlertText9 += ' Low Battery';
                  }
                  return plcAlertText9 === 'PLC: ' ? null : plcAlertText9;
                })()}
                alertTop="-35px"
                alertLeft="230px"
              />
              <Info
                title="Instrument Air"
                statistic={locationEquipment9?.IA?.ioValues?.low_air_pressure?.value ? 'Enabled' : 'Low Air Pressure'}
                color={locationEquipment9?.IA?.ioValues?.low_air_pressure?.value ? theme.onGreen : theme.alarm}
                label=""
              />
            </InfoControlRow>
            <PumpsRowCustom height="150px">
              <IRCompressor
                location={props.locations?.locations?.[6]?.name}
                compressorData={locationEquipment9?.C8}
                pipeAbove="right"
                pipeTypeAbove={[false, true, false]}
                pipeTypeBelow={[true, true, true]}
                pipeColorCenter={theme.pipeColors.hotWater}
                capBefore
                capLabel="To Hill"
                capLabelTop="10px"
                capLabelLeft="-65px"
                pipeColorAbove={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  compressorStartCommand: 'compressor_start_command',
                  compressorStopCommand: 'compressor_stop_command',
                  compressorModulate: 'compressor_modulate',
                  compressorModulateCommand: 'compressor_modulate_command',
                  compressorUnloaded: 'compressor_unloaded',
                  compressorUnloadCommand: 'compressor_unload_command',
                  compressorResetCommand: 'compressor_reset_command',
                  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: 'stage1_temperature',
                  stage1Pressure: 'stage1_pressure',
                  stage1Vibration: 'stage1_vibration',
                  stage2Temperature: 'stage2_temperature',
                  stage2Pressure: 'stage2_pressure',
                  stage2Vibration: 'stage2_vibration',
                  stage3Temperature: 'stage3_temperature',
                  stage3Pressure: 'stage3_pressure',
                  stage3Vibration: 'stage3_vibration',
                }}
                stats={[
                  {
                    stat: locationEquipment9?.C8?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment9?.C8?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment9?.C8?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment9?.C8?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment9?.C8?.ioValues?.oil_temperature?.value,
                    locationEquipment9?.C8?.ioValues?.oil_pressure?.value,
                    locationEquipment9?.C8?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment9?.C8?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment9?.C8?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment9?.C8?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment9?.C8?.ioValues?.oil_temperature?.id,
                    locationEquipment9?.C8?.ioValues?.oil_pressure?.id,
                    locationEquipment9?.C8?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment9?.C8?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment9?.C8?.ioValues?.system_pressure?.value,
                    locationEquipment9?.C8?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment9?.C8?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment9?.C8?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment9?.C8?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment9?.C8?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment9?.C8?.ioValues?.system_pressure?.id,
                    locationEquipment9?.C8?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              >
                <Statistic
                  float
                  floatColor={theme.pipeColors.air}
                  label={locationEquipment9?.Other?.ioValues[IOValueKeys.dischargeAirPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment9?.Other?.ioValues[IOValueKeys.dischargeAirPressure]?.value}
                />
              </IRCompressor>
              <PipePlaceholder
                color={theme.pipeColors.hotWater}
                horizontalCenter
              />
              <ValveConnect
                horizontal="center"
                pipeColorAbove={theme.pipeColors.hotWater}
                pipeColorBelow={theme.pipeColors.hotWater}
                percent
                percentTop="25px"
                percentLeft="4px"
                nameTop="-65px"
                nameLeft="4px"
                valveData={{ state: locationEquipment9?.Other?.ioValues?.[IOValueKeys.cv1Position]?.value, name: 'CV1' }}
                binary={false}
              />
              <CoolingTower
                controlWidth="110px"
                tempControl
                data={locationEquipment9?.CT1}
              // pipeAbove="none"
                pipeTypeAbove={[false, false, false]}
                pipeTypeBelow={[true, false, true]}
                setIOValue={props.setIOValue}
                max="100"
                stateKey={{
                  0: 'Off',
                  1: 'On',
                }}
                stateColorKey={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
                alarms={[
                  locationEquipment9?.CT1?.ioValues?.low_temperature,
                ]}
                stats={[
                  {
                    stat: locationEquipment9?.CT1?.ioValues?.[IOValueKeys.speed]?.value,
                    label: locationEquipment9?.CT1?.ioValues?.[IOValueKeys.speed]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment9?.CT1?.ioValues?.[IOValueKeys.temperature]?.value,
                    label: locationEquipment9?.CT1?.ioValues?.[IOValueKeys.temperature]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment9?.CT1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment9?.CT1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
            </PumpsRowCustom>
            <PumpsRowCustom height="130px">
              <IRCompressor
                location={props.locations?.locations?.[6]?.name}
                compressorData={locationEquipment9?.C9}
                pipeTypeAbove={[true, true, true]}
                pipeTypeBelow={[true, false, false]}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  compressorStartCommand: 'compressor_start_command',
                  compressorStopCommand: 'compressor_stop_command',
                  compressorModulate: 'compressor_modulate',
                  compressorModulateCommand: 'compressor_modulate_command',
                  compressorUnloaded: 'compressor_unloaded',
                  compressorUnloadCommand: 'compressor_unload_command',
                  compressorResetCommand: 'compressor_reset_command',
                  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: 'stage1_temperature',
                  stage1Pressure: 'stage1_pressure',
                  stage1Vibration: 'stage1_vibration',
                  stage2Temperature: 'stage2_temperature',
                  stage2Pressure: 'stage2_pressure',
                  stage2Vibration: 'stage2_vibration',
                  stage3Temperature: 'stage3_temperature',
                  stage3Pressure: 'stage3_pressure',
                  stage3Vibration: 'stage3_vibration',
                }}
                stats={[
                  {
                    stat: locationEquipment9?.C9?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment9?.C9?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment9?.C9?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment9?.C9?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment9?.C9?.ioValues?.oil_temperature?.value,
                    locationEquipment9?.C9?.ioValues?.oil_pressure?.value,
                    locationEquipment9?.C9?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment9?.C9?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment9?.C9?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment9?.C9?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment9?.C9?.ioValues?.oil_temperature?.id,
                    locationEquipment9?.C9?.ioValues?.oil_pressure?.id,
                    locationEquipment9?.C9?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment9?.C9?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment9?.C9?.ioValues?.system_pressure?.value,
                    locationEquipment9?.C9?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment9?.C9?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment9?.C9?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment9?.C9?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment9?.C9?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment9?.C9?.ioValues?.system_pressure?.id,
                    locationEquipment9?.C9?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <CoolingTower
                controlWidth="110px"
                tempControl
                data={locationEquipment9?.CT2}
              // pipeAbove="none"
                pipeTypeAbove={[true, false, true]}
                pipeTypeBelow={[true, false, false]}
                setIOValue={props.setIOValue}
                max="100"
                stateKey={{
                  0: 'Off',
                  1: 'On',
                }}
                stateColorKey={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
                alarms={[
                  locationEquipment9?.CT2?.ioValues?.low_temperature,
                ]}
                stats={[
                  {
                    stat: locationEquipment9?.CT2?.ioValues?.[IOValueKeys.speed]?.value,
                    label: locationEquipment9?.CT2?.ioValues?.[IOValueKeys.speed]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment9?.CT2?.ioValues?.[IOValueKeys.temperature]?.value,
                    label: locationEquipment9?.CT2?.ioValues?.[IOValueKeys.temperature]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment9?.CT2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment9?.CT2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
            </PumpsRowCustom>
            <PumpsRowCustom height="50px">
              <PipePlaceholder pipeAbove="right" connectAbove />
              <ValveConnect
                manual
                readOnly
                percent
                percentTop="22px"
                percentLeft="-5px"
                nameTop="-52px"
                nameLeft="4px"
                valveData={{ state: locationEquipment9?.Other?.ioValues?.[IOValueKeys.dv1Position]?.value, name: 'DV1' }}
                horizontal="above"
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
              />
              <PipePlaceholder pipeAbove="left" />
              <PipePlaceholder />
            </PumpsRowCustom>
            <PumpsRowCustom height="150px">
              <Pump2
                pipeAbove="right"
                connectAbove
                setIOValue={props.setIOValue}
                pumpData={locationEquipment9?.CP1}
                otherData={locationEquipment9?.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  localRemote: 'local_remote',
                  local: 'local_remote',
                  remote: 'local_remote',
                  drain: 'drain',
                  dischargePressure: 'cooling_system_pressure',
                }}
                writeValues={[1, 0, 0, 1]}
                readValues={[1, 0, 0, 1]}
                alarms={[
                // props.equipment?.P1?.ioValues[IOValueKeys.warning],
                // props.equipment?.P1?.ioValues[IOValueKeys.fault],
                // props.equipment?.P1?.ioValues[IOValueKeys.lowAmps],
                // props.equipment?.P1?.ioValues[IOValueKeys.masterAlarm],
                // props.equipment?.Other?.ioValues[IOValueKeys.lowSuctionPressure],
                ]}
                stats={[
                  {
                    stat: locationEquipment9?.CP1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment9?.CP1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment9?.CP1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment9?.CP1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                statuses={[
                  {
                    id: locationEquipment9?.CP1?.ioValues?.local_remote?.id,
                    status: locationEquipment9?.CP1?.ioValues?.local_remote?.value,
                    statusKey: {
                      0: 'Local',
                      1: 'Remote',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
              />
              <Pump2
                pipeAbove="left"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment9?.CP2}
                otherData={locationEquipment9?.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  localRemote: 'local_remote',
                  local: 'local_remote',
                  remote: 'local_remote',
                  drain: 'drain',
                }}
                writeValues={[1, 0]}
                alarms={[
                // props.equipment?.P1?.ioValues[IOValueKeys.warning],
                // props.equipment?.P1?.ioValues[IOValueKeys.fault],
                // props.equipment?.P1?.ioValues[IOValueKeys.lowAmps],
                // props.equipment?.P1?.ioValues[IOValueKeys.masterAlarm],
                // props.equipment?.Other?.ioValues[IOValueKeys.lowSuctionPressure],
                ]}
                stats={[
                  {
                    stat: locationEquipment9?.CP2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment9?.CP2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment9?.CP2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment9?.CP2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                statuses={[
                  {
                    id: locationEquipment9?.CP2?.ioValues?.local_remote?.id,
                    status: locationEquipment9?.CP2?.ioValues?.local_remote?.value,
                    statusKey: {
                      0: 'Local',
                      1: 'Remote',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
              />
              <PipePlaceholder />
              <PipePlaceholder />
            </PumpsRowCustom>
            <PumpsRowCustom height="70px">
              <Pond
                flex="12"
                color={theme.pipeColors.water}
              >
                <Pump2
                  pipeAbove="none"
                  pipeBelow="none"
                  controlPositionTop="-30px"
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment9?.WP1}
                  otherData={locationEquipment9?.Other}
                  stateColorKey={{
                    0: `${theme.offRed}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'disable_enable',
                    stop: 'disable_enable',
                    localRemote: 'local_remote',
                    local: 'local_remote',
                    remote: 'local_remote',
                    drain: 'drain',
                  }}
                  noControls
                  alarms={[
                    locationEquipment9?.Other?.ioValues?.low_well_level,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment9?.WP1?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment9?.WP1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment9?.WP1?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment9?.WP1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                {/* Info
                title="Well Level"
                statistic={0}
                label="Ft"
              /> */}
              </Pond>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

MaintenanceScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

MaintenanceScreen.defaultProps = {
  plcs: null,
  locations: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(MaintenanceScreen);
