import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pump,
  Pond,
  Valve,
  ValveConnect,
  PipePlaceholder,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRow,
  PumpsRowMedium,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  MultiInfo,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  MobileStatTable,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  InfoControl,
  MultiSetpointControl,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
// import { EquipmentPropType } from 'isno/lib/components/prop-types/Equipment';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLC } from '../actions';
// import LoadingScreen from './LoadingScreen';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow_rate',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  dischargeWaterPressure: 'discharge_water_pressure',
  dischargeWaterTemperature: 'discharge_water_temperature',
  suctionWaterPressure: 'suction_water_pressure',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function ConnectorScreen(props) {
  const theme = useTheme();
  const language = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(12), // (3)
        props.fetchLocation(4), // (11)
        props.fetchPLC(3),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(12); // (3)
    props.fetchLocation(4); // (11)
    props.fetchPLC(3);
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const pumpMainStatusKey = {
    0: 'READY',
    1: 'RUN',
    2: 'Switch',
    3: 'High Temp',
    4: 'Low Level',
    5: 'Run Fail',
    6: 'Disabled',
    7: 'Comm',
    8: 'Local',
    9: 'Fault',
    10: 'Suction',
    11: 'LEAD',
    12: 'E-Stop',
  };

  const pumpMainStatusColorKey = {
    0: `${theme.onGreen}`,
    1: `${theme.onGreen}`,
    2: `${theme.alarm}`,
    3: `${theme.alarm}`,
    4: `${theme.alarm}`,
    5: `${theme.alarm}`,
    6: `${theme.alarm}`,
    7: `${theme.alarm}`,
    8: `${theme.alarm}`,
    9: `${theme.alarm}`,
    10: `${theme.alarm}`,
    11: `${theme.onGreen}`,
    12: `${theme.alarm}`,
  };

  const locationEquipment3 = props.locations?.locations?.[12]?.equipment; // (3)
  const locationEquipment11 = props.locations?.locations?.[4]?.equipment; // (11)
  if (!locationEquipment3 || !locationEquipment11) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }
  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" flex="3" border label="Connector Pond">
            <InfoControlRow>
              <MultiInfo
                title="Connector Pond Flows"
                subtitles={['To Pond', 'From Pond']}
                statistics={[
                  locationEquipment3?.Other?.ioValues?.new_river_water_flow_to_connector_pond?.value,
                  locationEquipment11?.Other?.ioValues?.water_flow_to_new_river?.value,
                ]}
                ids={[
                  locationEquipment3?.Other?.ioValues?.new_river_water_flow_to_connector_pond?.id,
                  locationEquipment11?.Other?.ioValues?.water_flow_to_new_river?.id,
                ]}
                labels={[
                  locationEquipment3?.Other?.ioValues?.new_river_water_flow_to_connector_pond?.ioValueType?.units,
                  locationEquipment11?.Other?.ioValues?.water_flow_to_new_river?.ioValueType?.units,
                ]}
              />
              <Info
                title="Pond Level"
                precision={1}
                statistic={locationEquipment11?.Other?.ioValues?.pond_level?.value}
                label={locationEquipment11?.Other?.ioValues?.pond_level?.ioValueType?.units}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[3], language)} // plcId 3: ConnectorPond
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[3], theme)}
              />
              <Info
                title="Vacuum Pressure"
                precision={1}
                statistic={locationEquipment11?.Other?.ioValues?.vacuum_pressure?.value}
              // label="inHg"
                label={locationEquipment11?.Other?.ioValues?.vacuum_pressure?.ioValueType?.units}
              />
              {/* <Info
              title="Vacuum Pump"
              statistic={locationEquipment11?.VP?.status ? 'Enabled' : 'Disabled'}
              color="grey"
              label=""
              /> */}
              <InfoControl
                title="Vacuum Pump"
                statistic={locationEquipment11?.VP?.status ? 'Enabled' : 'Disabled'}
                color={locationEquipment11?.VP?.status ? theme.onGreen : theme.alarm}
                label=""
                setIOValue={props.setIOValue}
                button1WriteValue={1}
                button2WriteValue={0}
                button1WriteId={locationEquipment11?.VP?.ioValues?.disable_enable?.id}
                button2WriteId={locationEquipment11?.VP?.ioValues?.disable_enable?.id}
                alarms={[
                  locationEquipment11?.VP?.ioValues?.low_vacuum_pressure,
                ]}
              />
              <InfoControl
                title="Instrument Air"
                statistic={locationEquipment11?.IA?.status ? 'Enabled' : 'Disabled'}
                color={locationEquipment11?.IA?.status ? theme.onGreen : theme.alarm}
                label=""
                setIOValue={props.setIOValue}
                button1WriteValue={1}
                button2WriteValue={0}
                button1WriteId={locationEquipment11?.IA?.ioValues?.disable_enable?.id}
                button2WriteId={locationEquipment11?.IA?.ioValues?.disable_enable?.id}
                alarms={[
                  locationEquipment11?.IA?.ioValues?.low_air_pressure,
                ]}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment11?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: null,
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <MultiSetpointControl
                title="Pump Speed Setpoints"
                labels={['TP4 VFD', 'TP5 VFD', 'TP6 VFD']}
                statistics={[
                  `${locationEquipment11?.TP4?.ioValues?.speed_setpoint?.value}`,
                  `${locationEquipment11?.TP5?.ioValues?.speed_setpoint?.value}`,
                  `${locationEquipment11?.TP6?.ioValues?.speed_setpoint?.value}`,
                ]}
                units={[
                  `${locationEquipment11?.TP4?.ioValues?.speed_setpoint?.ioValueType?.units}`,
                  `${locationEquipment11?.TP5?.ioValues?.speed_setpoint?.ioValueType?.units}`,
                  `${locationEquipment11?.TP6?.ioValues?.speed_setpoint?.ioValueType?.units}`,
                ]}
                setIOValue={props.setIOValue}
                IOValueIDs={[
                  `${locationEquipment11?.TP4?.ioValues?.speed_setpoint?.id}`,
                  `${locationEquipment11?.TP5?.ioValues?.speed_setpoint?.id}`,
                  `${locationEquipment11?.TP6?.ioValues?.speed_setpoint?.id}`,
                ]}
                max={['100', '100', '100']}
                min={['0', '0', '0']}
                precision={[1, 1, 1]}
              />
              {/* <AutoValveControl
              title="RV1 Control"
              binary
              button1text="Open"
              button2text="Closed"
              button1WriteValue={100}
              button2WriteValue={0}
              button1IOValueID={locationEquipment11.RV1?.ioValues?.rv1_position?.id}
              button2IOValueID={locationEquipment11.RV1?.ioValues?.rv1_position?.id}
              valveData={locationEquipment11.RV1}
              setIOValue={props.setIOValue}
              /> */}
              {/* <AutoValveControl
              title="CV4 Control"
              binary
              button1text="Open"
              button2text="Closed"
              button1WriteValue={1}
              button2WriteValue={0}
              button1IOValueID={locationEquipment11.CV4?.ioValues?.close_open?.id}
              button2IOValueID={locationEquipment11.CV4?.ioValues?.close_open?.id}
              valveData={locationEquipment11.CV4}
              setIOValue={props.setIOValue}
              /> */}
              {/* <AutoValveControl
              title="CV5 Control"
              binary
              button1text="Open"
              button2text="Closed"
              button1WriteValue={1}
              button2WriteValue={0}
              button1IOValueID={locationEquipment11.CV5?.ioValues?.close_open?.id}
              button2IOValueID={locationEquipment11.CV5?.ioValues?.close_open?.id}
              valveData={locationEquipment11.CV5}
              setIOValue={props.setIOValue}
              /> */}
              {/* <AutoValveControl
              title="CV6 Control"
              binary
              button1text="Open"
              button2text="Closed"
              button1WriteValue={1}
              button2WriteValue={0}
              button1IOValueID={locationEquipment11.CV6?.ioValues?.close_open?.id}
              button2IOValueID={locationEquipment11.CV6?.ioValues?.close_open?.id}
              valveData={locationEquipment11.CV6}
              setIOValue={props.setIOValue}
              /> */}
              <MobileStatTable
                title="Water System Metrics"
                stats={
                [
                  {
                    description: 'Discharge Water Pressure',
                    stat: locationEquipment11?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.value,
                    label: locationEquipment11?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Temperature',
                    stat: locationEquipment11?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.value,
                    label: locationEquipment11?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.ioValueType?.units,
                  },
                ]
              }
              />
            </InfoControlRow>
            <PumpsRowExtraSmall styles={styles.hiddenMobile}>
              <PipePlaceholder capLabel="Pond Recharge" capLabelTop="10px" capLabelLeft="10px" capBefore horizontalAbove color={theme.pipeColors.water} />
              <ValveConnect
                pipeAbove="left"
                alignRight
                reverseValve
                nameLeft="-70px"
                percent
                percentTop="50px"
                controls
                controlsLeft="45px"
                valveData={{ ...locationEquipment11.RV1, state: locationEquipment11.RV1?.ioValues?.rv1_position?.value }}
                writeValues={[100, 0]}
                readValues={[100, 0]}
                setIOValue={props.setIOValue}
                valveStateKeys={{
                  0: '0',
                  100: '100',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  100: theme.onGreen,
                }}
                IOValueKeys={{
                  position: 'rv1_position',
                  open: 'rv1_position',
                  close: 'rv1_position',
                }}
                binary={false}
                greenImageKey="100"
              />
              <PipePlaceholder hidden />
              <PipePlaceholder pipeAbove="right" />
              <PipePlaceholder capAfter capLabel="To New River PH" capLabelTop="15px" capLabelLeft="-120px" horizontalAbove color={theme.pipeColors.water}>
                <Statistic
                  label={locationEquipment11?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment11?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.value}
                />
                <Statistic
                  precision={1}
                  label={locationEquipment11?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.ioValueType?.units}
                  border
                  statistic={locationEquipment11?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.value}
                />
              </PipePlaceholder>
            </PumpsRowExtraSmall>
            <PumpsRowExtraSmall>
              <PipePlaceholder hidden />
              <ValveConnect
                showOnMobile
                reverseValve
                pipeSizeAbove="large"
                pipeAbove="right"
                nameLeft="-70px"
                percent
                percentTop="50px"
                controls
                controlsLeft="45px"
                valveData={{ ...locationEquipment11.CV6, state: locationEquipment11.CV6?.status }}
                setIOValue={props.setIOValue}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
                IOValueKeys={{
                  opened: 'close_open',
                  closed: 'close_open',
                  open: 'close_open',
                  close: 'close_open',
                }}
                greenImageKey="Opened"
              />
              <ValveConnect
                reverseValve
                pipeSizeAbove="large"
                pipeAbove="middle"
                nameLeft="-70px"
                percent
                percentTop="50px"
                controls
                controlsLeft="45px"
                valveData={{ ...locationEquipment11.CV5, state: locationEquipment11.CV5?.status }}
                setIOValue={props.setIOValue}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
                IOValueKeys={{
                  opened: 'close_open',
                  closed: 'close_open',
                  open: 'close_open',
                  close: 'close_open',
                }}
                greenImageKey="Opened"
              />
              <ValveConnect
                reverseValve
                pipeSizeAbove="large"
                pipeAbove="left"
                nameLeft="-70px"
                percent
                percentTop="50px"
                controls
                controlsLeft="45px"
                valveData={{ ...locationEquipment11.CV4, state: locationEquipment11.CV4?.status }}
                setIOValue={props.setIOValue}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
                IOValueKeys={{
                  opened: 'close_open',
                  closed: 'close_open',
                  open: 'close_open',
                  close: 'close_open',
                }}
                greenImageKey="Opened"
              />
            </PumpsRowExtraSmall>
            <PumpsRowMedium>
              <PipePlaceholder capBefore capLabel="From Connector Pond" capLabelTop="-25px" capLabelLeft="10px" horizontalBelow color={theme.pipeColors.water} size="large" />
              <Pump
                percent
                pipeBelow="middle"
                pipeSizeBelow="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment11.TP6}
                otherData={locationEquipment11.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  speed: 'speed',
                }}
                alarms={[
                  locationEquipment11.TP6?.ioValues?.low_level_warning,
                  locationEquipment11.TP6?.ioValues?.low_level_alarm,
                ]}
                stats={[
                  {
                    stat: locationEquipment11?.TP6?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment11?.TP6?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment11?.TP6?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment11?.TP6?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeBelow="middle"
                pipeSizeBelow="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment11.TP5}
                otherData={locationEquipment11.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  speed: 'speed',
                }}
                alarms={[
                  locationEquipment11.TP5?.ioValues?.low_level_warning,
                  locationEquipment11.TP5?.ioValues?.low_level_alarm,
                ]}
                stats={[
                  {
                    stat: locationEquipment11?.TP5?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment11?.TP5?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment11?.TP5?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment11?.TP5?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeBelow="left"
                pipeSizeBelow="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment11.TP4}
                otherData={locationEquipment11.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  speed: 'speed',
                }}
                alarms={[
                  locationEquipment11.TP4?.ioValues?.low_level_warning,
                  locationEquipment11.TP4?.ioValues?.low_level_alarm,
                ]}
                stats={[
                  {
                    stat: locationEquipment11?.TP4?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment11?.TP4?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment11?.TP4?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment11?.TP4?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
            </PumpsRowMedium>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: ${theme.backgroundColor};
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

ConnectorScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchPLC: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

ConnectorScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLC })(ConnectorScreen);
