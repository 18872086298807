import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pump,
  Pond,
  ValveConnect,
  PipePlaceholder,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  MultiInfo,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  // MobileStatTable,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  VFDPumpControl,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
// import { EquipmentPropType } from 'isno/lib/components/prop-types/Equipment';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLC } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  dischargeWaterPressure: 'discharge_water_pressure',
  dischargeWaterTemperature: 'discharge_water_temperature',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  wellLevel: 'transfer_system_well_level',
  ib1: 'ib1_open_close',
  ib2: 'ib2_open_close',
  ib3: 'ib3_open_close',
  ib4: 'ib4_open_close',
  toPondFlow: 'new_river_water_flow_to_connector_pond',
  fromPondFlow: 'water_flow_to_new_river',
  riverLevel: 'river_flow',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function TransferScreen(props) {
  const theme = useTheme();
  const language = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(12), // (3)
        props.fetchLocation(4), // (11)
        props.fetchPLC(10),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(12); // (3)
    props.fetchLocation(4); // (11)
    props.fetchPLC(10);
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  // console.log(props);
  const locationEquipment3 = props.locations?.locations?.[12]?.equipment; // (3)
  const locationEquipment11 = props.locations?.locations?.[4]?.equipment; // (11)
  if (!props.locations?.locations?.[12]) { // (3)
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }
  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer flex="3" border backgroundColor={theme.backgroundColor} label="Transfer System">
            <InfoControlRow>
              <MultiInfo
                title="Connector Pond Flows"
                subtitles={['To Pond', 'From Pond']}
                statistics={[
                  locationEquipment3?.Other?.ioValues[IOValueKeys.toPondFlow]?.value,
                  locationEquipment11?.Other?.ioValues[IOValueKeys.fromPondFlow]?.value,
                ]}
                labels={[
                  locationEquipment3?.Other?.ioValues[IOValueKeys.toPondFlow]?.ioValueType?.units,
                  locationEquipment11?.Other?.ioValues[IOValueKeys.fromPondFlow]?.ioValueType?.units,
                ]}
                ids={[
                  locationEquipment3?.Other?.ioValues[IOValueKeys.toPondFlow]?.id,
                  locationEquipment11?.Other?.ioValues[IOValueKeys.fromPondFlow]?.id,
                ]}
              />
              <Info
                title="River Level"
                statistic={locationEquipment3?.Other?.ioValues[IOValueKeys.riverLevel]?.value}
                label={locationEquipment3?.Other?.ioValues[IOValueKeys.riverLevel]?.ioValueType?.units}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[10], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[10], theme)}
              />
              <AlarmReset
                title="System reset"
                buttonText="Reset"
                alarmData={locationEquipment3?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: null,
                  masterAlarmReset: 'alarm_reset',
                }}
              />
              <VFDPumpControl
                title="Well Level PID"
                autoPrecision={1}
                manPrecision={1}
                pumpData={{ id: `Well_Info_${locationEquipment3?.Other?.ioValues?.[IOValueKeys.wellLevel]?.id}` }}
                pidData={locationEquipment3?.['Well PID']}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoMode: 'manual_auto',
                  manualMode: 'manual_auto',
                  autoManual: 'manual_auto',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                max="20"
              />
            </InfoControlRow>
            <PumpsRowExtraSmall styles={styles.hiddenMobile}>
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <PipePlaceholder pipeAbove="right" />
              <PipePlaceholder capAfter capLabel="To River PH / Connector Pond" capLabelTop="20px" capLabelLeft="-210px" horizontalAbove color={theme.pipeColors.water}>
                <Statistic
                  label={locationEquipment3?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment3?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.value}
                />
                <Statistic
                  precision={1}
                  label={locationEquipment3?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.ioValueType?.units}
                  border
                  statistic={locationEquipment3?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.value}
                />
              </PipePlaceholder>
            </PumpsRowExtraSmall>
            <PumpsRowMedium>
              <PipePlaceholder hidden />
              <Pump
                percent
                pipeAbove="right"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment3?.TP1}
                otherData={locationEquipment3?.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                alarms={[]}
                stats={[
                  {
                    stat: locationEquipment3?.TP1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment3?.TP1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment3?.TP1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment3?.TP1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment3?.TP2}
                otherData={locationEquipment3?.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                alarms={[
                // props.equipment?.P1?.ioValues[IOValueKeys.warning],
                // props.equipment?.P1?.ioValues[IOValueKeys.fault],
                // props.equipment?.P1?.ioValues[IOValueKeys.lowAmps],
                // props.equipment?.P1?.ioValues[IOValueKeys.masterAlarm],
                // props.equipment?.Other?.ioValues[IOValueKeys.lowSuctionPressure],
                ]}
                stats={[
                  {
                    stat: locationEquipment3?.TP2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment3?.TP2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment3?.TP2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment3?.TP2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="left"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment3?.TP3}
                otherData={locationEquipment3?.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                alarms={[
                // props.equipment?.P1?.ioValues[IOValueKeys.warning],
                // props.equipment?.P1?.ioValues[IOValueKeys.fault],
                // props.equipment?.P1?.ioValues[IOValueKeys.lowAmps],
                // props.equipment?.P1?.ioValues[IOValueKeys.masterAlarm],
                // props.equipment?.Other?.ioValues[IOValueKeys.lowSuctionPressure],
                ]}
                stats={[
                  {
                    stat: locationEquipment3?.TP3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment3?.TP3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment3?.TP3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment3?.TP3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
            </PumpsRowMedium>
            <PumpsRowExtraSmall>
              <Pond
                title="Infiltration Bed"
                color={theme.pipeColors.water}
                id={`Well_Info_${locationEquipment3?.Other?.ioValues?.[IOValueKeys.wellLevel]?.id}`}
              // highlightColor="#00ffffff"
                alertText={locationEquipment3?.Other?.ioValues?.all_infiltration_beds_closed?.value ? 'All Infiltration Beds Closed' : null}
                alertTop="76px"
                alertLeft=" px"
              >
                <Info
                  title="Well Level"
                  precision={1}
                  statistic={locationEquipment3?.Other?.ioValues?.[IOValueKeys.wellLevel]?.value}
                  label={locationEquipment3?.Other?.ioValues?.[IOValueKeys.wellLevel]?.ioValueType?.units}
                  alertText={(() => {
                    if (locationEquipment3?.Other?.ioValues?.low_well_level?.value) {
                      return 'Low Well Level';
                    } else if (locationEquipment3?.Other?.ioValues?.low_well_level_warning?.value) {
                      return 'Low Well Level Warning';
                    } else {
                      return null;
                    }
                  })()}
                  alertTop="-23px"
                  alertLeft={locationEquipment3?.Other?.ioValues?.low_well_level_warning?.value ? '-50px' : '-15px'}
                />
              </Pond>
            </PumpsRowExtraSmall>
            <PumpsRowExtraSmall>
              <PipePlaceholder hidden />
              <ValveConnect
                manual
                readOnly
                percent
                percentTop="50px"
                percentLeft="-15px"
                reverseValve
                nameTop="7px"
                nameLeft="-55px"
                valveStateKeys={{
                  0: 'Open',
                  1: 'Closed',
                }}
                valveStateColorKeys={{
                  0: theme.onGreen,
                  1: theme.offRed,
                }}
                valveStateTextColor={{
                  0: theme.textColorDark,
                  1: '',
                }}
                valveData={{ state: locationEquipment3?.Other?.ioValues?.[IOValueKeys.ib1]?.value, name: 'IB1' }}
              />
              <ValveConnect
                manual
                readOnly
                percent
                percentTop="50px"
                percentLeft="-15px"
                reverseValve
                nameTop="7px"
                nameLeft="-55px"
                valveStateKeys={{
                  0: 'Open',
                  1: 'Closed',
                }}
                valveStateColorKeys={{
                  0: theme.onGreen,
                  1: theme.offRed,
                }}
                valveStateTextColor={{
                  0: theme.textColorDark,
                  1: '',
                }}
                valveData={{ state: locationEquipment3?.Other?.ioValues?.[IOValueKeys.ib2]?.value, name: 'IB2' }}
              />
              <ValveConnect
                manual
                readOnly
                percent
                percentTop="50px"
                percentLeft="-15px"
                reverseValve
                nameTop="7px"
                nameLeft="-55px"
                valveStateKeys={{
                  0: 'Open',
                  1: 'Closed',
                }}
                valveStateColorKeys={{
                  0: theme.onGreen,
                  1: theme.offRed,
                }}
                valveStateTextColor={{
                  0: theme.textColorDark,
                  1: '',
                }}
                valveData={{ state: locationEquipment3?.Other?.ioValues?.[IOValueKeys.ib3]?.value, name: 'IB3' }}
              />
              <ValveConnect
                manual
                readOnly
                percent
                percentTop="50px"
                percentLeft="-15px"
                reverseValve
                nameTop="7px"
                nameLeft="-55px"
                valveStateKeys={{
                  0: 'Open',
                  1: 'Closed',
                }}
                valveStateColorKeys={{
                  0: theme.onGreen,
                  1: theme.offRed,
                }}
                valveStateTextColor={{
                  0: theme.textColorDark,
                  1: '',
                }}
                valveData={{ state: locationEquipment3?.Other?.ioValues?.[IOValueKeys.ib4]?.value, name: 'IB4' }}
              />
            </PumpsRowExtraSmall>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

TransferScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchPLC: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

TransferScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLC })(TransferScreen);
