import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pump,
  Pump2,
  ValveConnect,
  Pipe,
  PipePlaceholder,
  IRCompressor,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  PumpsRowMedium,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  // MobileStatTable,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  VFDPumpControl,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
// import { EquipmentPropType } from 'isno/lib/components/prop-types/Equipment';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLC } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  highSuctionPressure: 'high_system_suction_water_pressure',
  highDischargeWaterPressure: 'high_system_discharge_water_pressure',
  highBackWaterPressure: 'high_system_back_water_pressure',
  medDischargeWaterPressure: 'medium_system_discharge_water_pressure',
  medBackWaterPressure: 'medium_system_back_water_pressure',
  medSuctionWaterPressure: 'medium_system_suction_water_pressure',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  highSealTemperature: 'high_seal_temperature',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',

  cv1Position: 'cv1_position',
  cv2Position: 'cv2_position',
  bv1Position: 'bv1_position',
  bv2Position: 'bv2_position',
  dischargeAirPressure: 'discharge_air_pressure',
  dischargeAirFlow: 'discharge_air_flow',
  coolingSysPressure: 'cooling_system_pressure',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function AnnexScreen(props) {
  const theme = useTheme();
  const language = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(11), // 4
        props.fetchLocation(1), // 14
        props.fetchPLC(9),
        props.fetchPLC(1),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchPLC(9);
    props.fetchPLC(1);
    props.fetchLocation(11); // 4
    props.fetchLocation(1); // 14
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment4 = props.locations?.locations?.[11]?.equipment; // 4
  const locationEquipment14 = props.locations?.locations?.[1]?.equipment; // 14
  // const locationEquipment13 = props.locations?.locations?.[13]?.equipment; // ASK ABOUT THIS ONE ON ALARM RESET? guessing justt a typo
  if (!locationEquipment4 || !locationEquipment14) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }
  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content; display: flex; flex-direction: row; flex-wrap: nowrap; @media only screen and (max-width: 800px) {flex-direction: column;}">
          <LabeledContainer height="fit-content" border label="Annex Compressors" id="annexCompressors">
            <InfoControlRow>
              <Info
                img={PLC}
                title="Annex PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[1], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[1], theme)}
              // alertText={plcAlertText14}
                alertText={(() => {
                  let plcAlertText14 = 'Annex PLC: ';
                  if (locationEquipment14?.Other?.ioValues?.plc_terminal_mode_off?.value) {
                    plcAlertText14 = 'PLC Terminal Mode Off |';
                  }
                  if (locationEquipment14?.Other?.ioValues?.plc_low_battery?.value) {
                    plcAlertText14 += ' PLC Low Battery';
                  }
                  return plcAlertText14 === 'Annex PLC: ' ? null : plcAlertText14;
                })()}
                alertTop="-20px"
                alertLeft="28px"
              />
              <Info
                title="Secondary Current"
                statistic={locationEquipment14?.Other?.ioValues?.secondary_current?.value}
              // color=""
                label={locationEquipment14?.Other?.ioValues?.secondary_current?.ioValueType?.units}
              />
              <Info
                title="Instrument Air"
                statistic={locationEquipment14?.IA?.ioValues?.low_air_pressure?.value === 0 ? 'Enabled' : 'Low Pressure'}
                color={locationEquipment14?.IA?.ioValues?.low_air_pressure?.value === 0 ? theme.onGreen : theme.alarm}
                label=""
              />
              {/* <AlarmReset
              title="System reset"
              buttonText="Reset"
              alarmData={locationEquipment13?.Other}
              setIOValue={props.setIOValue}
              alarmKeys={{
                masterAlarm: null,
                masterAlarmReset: 'alarm_reset',
              }}
              resetWriteValue={1}
            /> */}
              {/* <Info
              title="Instrument Air"
              statistic={locationEquipment14?.IA?.status}
              // color="grey"
              label=""
            /> */}
              {/* <AlarmReset
              title="System reset"
              buttonText="Reset"
              alarmData={props.equipment?.Other}
              setIOValue={props.setIOValue}
            /> */}
            </InfoControlRow>
            <PumpsRowCustom height="140px">
              <IRCompressor
                location={props.locations?.locations?.[1]?.name}
                compressorData={locationEquipment14?.C1}
                pipeAbove="right"
                pipeTypeBelow={[true, true, true]}
                capBefore
                capLabel="To Hill"
                capLabelTop="15px"
                capLabelLeft="-65px"
                controlPositionTop="-35px"
                pipeColorAbove={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  compressorStartCommand: 'compressor_start_command',
                  compressorStopCommand: 'compressor_stop_command',
                  compressorModulate: 'compressor_modulate',
                  compressorModulateCommand: 'compressor_modulate_command',
                  compressorUnloaded: 'compressor_unloaded',
                  compressorUnloadCommand: 'compressor_unload_command',
                  compressorResetCommand: 'compressor_reset_command',
                  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: 'stage1_temperature',
                  stage1Pressure: 'stage1_pressure',
                  stage1Vibration: 'stage1_vibration',
                  stage2Temperature: 'stage2_temperature',
                  stage2Pressure: 'stage2_pressure',
                  stage2Vibration: 'stage2_vibration',
                  stage3Temperature: 'stage3_temperature',
                  stage3Pressure: 'stage3_pressure',
                  stage3Vibration: 'stage3_vibration',
                }}
                stats={[
                  {
                    stat: locationEquipment14?.C1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment14?.C1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment14?.C1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment14?.C1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment14?.C1?.ioValues?.oil_temperature?.value,
                    locationEquipment14?.C1?.ioValues?.oil_pressure?.value,
                    locationEquipment14?.C1?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment14?.C1?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment14?.C1?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment14?.C1?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment14?.C1?.ioValues?.oil_temperature?.id,
                    locationEquipment14?.C1?.ioValues?.oil_pressure?.id,
                    locationEquipment14?.C1?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment14?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment14?.C1?.ioValues?.system_pressure?.value,
                    locationEquipment14?.C1?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment14?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment14?.C1?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment14?.C1?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment14?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment14?.C1?.ioValues?.system_pressure?.id,
                    locationEquipment14?.C1?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              >
                <Statistic
                  float
                  floatColor={theme.pipeColors.air}
                  label="Psi"
                  border
                  statistic={locationEquipment14?.Other?.ioValues[IOValueKeys.dischargeAirPressure]?.value}
                />
                <Pipe color={theme.pipeColors.air} horizontal>
                  <Statistic
                    float
                    floatColor={theme.pipeColors.air}
                    label="Cfm"
                    border
                    statistic={locationEquipment14?.Other?.ioValues[IOValueKeys.dischargeAirFlow]?.value}
                  />
                </Pipe>
              </IRCompressor>
              <PipePlaceholder hidden />
            </PumpsRowCustom>
            <PumpsRowCustom height="130px">
              <IRCompressor
                location={props.locations?.locations?.[1]?.name}
                compressorData={locationEquipment14?.C2}
                pipeTypeAbove={[true, true, true]}
                pipeTypeBelow={[true, true, true]}
                controlPositionTop="-35px"
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  compressorStartCommand: 'compressor_start_command',
                  compressorStopCommand: 'compressor_stop_command',
                  compressorModulate: 'compressor_modulate',
                  compressorModulateCommand: 'compressor_modulate_command',
                  compressorUnloaded: 'compressor_unloaded',
                  compressorUnloadCommand: 'compressor_unload_command',
                  compressorResetCommand: 'compressor_reset_command',
                  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: 'stage1_temperature',
                  stage1Pressure: 'stage1_pressure',
                  stage1Vibration: 'stage1_vibration',
                  stage2Temperature: 'stage2_temperature',
                  stage2Pressure: 'stage2_pressure',
                  stage2Vibration: 'stage2_vibration',
                  stage3Temperature: 'stage3_temperature',
                  stage3Pressure: 'stage3_pressure',
                  stage3Vibration: 'stage3_vibration',
                }}
                stats={[
                  {
                    stat: locationEquipment14?.C2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment14?.C2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment14?.C2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment14?.C2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment14?.C2?.ioValues?.oil_temperature?.value,
                    locationEquipment14?.C2?.ioValues?.oil_pressure?.value,
                    locationEquipment14?.C2?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment14?.C2?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment14?.C2?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment14?.C2?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment14?.C2?.ioValues?.oil_temperature?.id,
                    locationEquipment14?.C2?.ioValues?.oil_pressure?.id,
                    locationEquipment14?.C2?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment14?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment14?.C2?.ioValues?.system_pressure?.value,
                    locationEquipment14?.C2?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment14?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment14?.C2?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment14?.C2?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment14?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment14?.C2?.ioValues?.system_pressure?.id,
                    locationEquipment14?.C2?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <PipePlaceholder hidden />
            </PumpsRowCustom>
            <PumpsRowCustom height="130px">
              <IRCompressor
                location={props.locations?.locations?.[1]?.name}
                compressorData={locationEquipment14?.C3}
                pipeTypeAbove={[true, true, true]}
                pipeTypeBelow={[true, true, true]}
                controlPositionTop="-35px"
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  compressorStartCommand: 'compressor_start_command',
                  compressorStopCommand: 'compressor_stop_command',
                  compressorModulate: 'compressor_modulate',
                  compressorModulateCommand: 'compressor_modulate_command',
                  compressorUnloaded: 'compressor_unloaded',
                  compressorUnloadCommand: 'compressor_unload_command',
                  compressorResetCommand: 'compressor_reset_command',
                  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: 'stage1_temperature',
                  stage1Pressure: 'stage1_pressure',
                  stage1Vibration: 'stage1_vibration',
                  stage2Temperature: 'stage2_temperature',
                  stage2Pressure: 'stage2_pressure',
                  stage2Vibration: 'stage2_vibration',
                  stage3Temperature: 'stage3_temperature',
                  stage3Pressure: 'stage3_pressure',
                  stage3Vibration: 'stage3_vibration',
                }}
                stats={[
                  {
                    stat: locationEquipment14?.C3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment14?.C3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment14?.C3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment14?.C3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment14?.C3?.ioValues?.oil_temperature?.value,
                    locationEquipment14?.C3?.ioValues?.oil_pressure?.value,
                    locationEquipment14?.C3?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment14?.C3?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment14?.C3?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment14?.C3?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment14?.C3?.ioValues?.oil_temperature?.id,
                    locationEquipment14?.C3?.ioValues?.oil_pressure?.id,
                    locationEquipment14?.C3?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment14?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment14?.C3?.ioValues?.system_pressure?.value,
                    locationEquipment14?.C3?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment14?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment14?.C3?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment14?.C3?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment14?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment14?.C3?.ioValues?.system_pressure?.id,
                    locationEquipment14?.C3?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <PipePlaceholder hidden />
            </PumpsRowCustom>
            <PumpsRowCustom height="160px">
              <IRCompressor
                location={props.locations?.locations?.[1]?.name}
                compressorData={locationEquipment14?.C4}
                capAfter
                pipeColorCenter={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.hotWater}
                pipeTypeAbove={[true, true, true]}
                pipeTypeBelow={[false, false, true]}
                controlPositionTop="-35px"
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  compressorStartCommand: 'compressor_start_command',
                  compressorStopCommand: 'compressor_stop_command',
                  compressorModulate: 'compressor_modulate',
                  compressorModulateCommand: 'compressor_modulate_command',
                  compressorUnloaded: 'compressor_unloaded',
                  compressorUnloadCommand: 'compressor_unload_command',
                  compressorResetCommand: 'compressor_reset_command',
                  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: 'stage1_temperature',
                  stage1Pressure: 'stage1_pressure',
                  stage1Vibration: 'stage1_vibration',
                  stage2Temperature: 'stage2_temperature',
                  stage2Pressure: 'stage2_pressure',
                  stage2Vibration: 'stage2_vibration',
                  stage3Temperature: 'stage3_temperature',
                  stage3Pressure: 'stage3_pressure',
                  stage3Vibration: 'stage3_vibration',
                }}
                centerChild={(
                  <Pipe
                    horizontal
                    color={theme.pipeColors.water}
                  >
                    <Statistic
                      float
                      label={locationEquipment14?.Other?.ioValues[IOValueKeys.coolingSysPressure]?.ioValueType?.units}
                      border
                      statistic={locationEquipment14?.Other?.ioValues[IOValueKeys.coolingSysPressure]?.value}
                    />
                  </Pipe>
                )}
                stats={[
                  {
                    stat: locationEquipment14?.C4?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment14?.C4?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment14?.C4?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment14?.C4?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment14?.C4?.ioValues?.oil_temperature?.value,
                    locationEquipment14?.C4?.ioValues?.oil_pressure?.value,
                    locationEquipment14?.C4?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment14?.C4?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment14?.C4?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment14?.C4?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment14?.C4?.ioValues?.oil_temperature?.id,
                    locationEquipment14?.C4?.ioValues?.oil_pressure?.id,
                    locationEquipment14?.C4?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment14?.C4?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment14?.C4?.ioValues?.system_pressure?.value,
                    locationEquipment14?.C4?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment14?.C4?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment14?.C4?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment14?.C4?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment14?.C4?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment14?.C4?.ioValues?.system_pressure?.id,
                    locationEquipment14?.C4?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <Pump2
                localRemote
                pipeAbove="none"
                pipeBelow="none"
                controlWidth="135px"
                controlPositionTop="-75px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment14?.CP1}
                otherData={locationEquipment14?.Other}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  localRemote: 'local_remote',
                  local: 'local_remote',
                  remote: 'local_remote',
                // drain: 'drain',
                }}
                writeValues={[1, 0, 0, 1]}
                readValues={[1, 0, 0, 1]}
                alarms={[
                // props.equipment?.P1?.ioValues[IOValueKeys.warning],
                // props.equipment?.P1?.ioValues[IOValueKeys.fault],
                // props.equipment?.P1?.ioValues[IOValueKeys.lowAmps],
                // props.equipment?.P1?.ioValues[IOValueKeys.masterAlarm],
                // props.equipment?.Other?.ioValues[IOValueKeys.lowSuctionPressure],
                ]}
                stats={[
                  {
                    stat: locationEquipment14?.CP1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment14?.CP1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment14?.CP1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment14?.CP1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                statuses={[
                  {
                    id: locationEquipment14.CP1?.ioValues?.local_remote?.id,
                    status: locationEquipment14.CP1?.ioValues?.local_remote?.value,
                    statusKey: {
                      0: 'Local',
                      1: 'Remote',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                // {
                //   id: locationEquipment14.CP1?.ioValues?.drain?.id,
                //   status: locationEquipment14.CP1?.ioValues?.drain?.value,
                //   statusKey: {
                //     0: '',
                //     1: 'Draining',
                //   },
                //   statusColorKey: {
                //     0: '', // These aren't the "named" colors but I like them for this use case
                //     1: theme.auto,
                //   },
                // },
                ]}
              />
            </PumpsRowCustom>
          </LabeledContainer>
          <LabeledContainer height="fit-content" border label="Summit Air" id="summitAir">
            <InfoControlRow>
              <Info
                img={PLC}
                title="Summit Air PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[9], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[9], theme)}
              // alertText={plcAlertText4}
                alertText={(() => {
                  let plcAlertText4 = 'Summit Air PLC: ';
                  if (locationEquipment4?.Other?.ioValues?.plc_terminal_mode_off?.value) {
                    plcAlertText4 = ' Terminal Mode Off |';
                  }
                  if (locationEquipment4?.Other?.ioValues?.plc_low_battery?.value) {
                    plcAlertText4 += ' Low Battery';
                  }
                  // return plcAlertText4 === '' ? null : plcAlertText4;
                  return plcAlertText4 === 'Summit Air PLC: ' ? null : plcAlertText4;
                })()}
                alertTop="-40px"
                alertLeft="230px"
              />
            </InfoControlRow>
            <PumpsRowCustom height="140px">
              <IRCompressor
                location={props.locations?.locations?.[11]?.name}
                compressorData={locationEquipment4?.C5}
                pipeAbove="right"
                capBefore
                capLabel="To Hill"
                capLabelTop="15px"
                capLabelLeft="-46px"
                controlPositionTop="-35px"
                pipeTypeBelow={[false, false, false]}
                pipeColorAbove={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  compressorStartCommand: 'compressor_start_command',
                  compressorStopCommand: 'compressor_stop_command',
                  compressorModulate: 'compressor_modulate',
                  compressorModulateCommand: 'compressor_modulate_command',
                  compressorUnloaded: 'compressor_unloaded',
                  compressorUnloadCommand: 'compressor_unload_command',
                  compressorResetCommand: 'compressor_reset_command',
                  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: 'stage1_temperature',
                  stage1Pressure: 'stage1_pressure',
                  stage1Vibration: 'stage1_vibration',
                  stage2Temperature: 'stage2_temperature',
                  stage2Pressure: 'stage2_pressure',
                  stage2Vibration: 'stage2_vibration',
                  stage3Temperature: 'stage3_temperature',
                  stage3Pressure: 'stage3_pressure',
                  stage3Vibration: 'stage3_vibration',
                }}
                stats={[
                  {
                    stat: locationEquipment4?.C5?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment4?.C5?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.C5?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment4?.C5?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment4?.C5?.ioValues?.oil_temperature?.value,
                    locationEquipment4?.C5?.ioValues?.oil_pressure?.value,
                    locationEquipment4?.C5?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment4?.C5?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment4?.C5?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment4?.C5?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment4?.C5?.ioValues?.oil_temperature?.id,
                    locationEquipment4?.C5?.ioValues?.oil_pressure?.id,
                    locationEquipment4?.C5?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment4?.C5?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment4?.C5?.ioValues?.system_pressure?.value,
                    locationEquipment4?.C5?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment4?.C5?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment4?.C5?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment4?.C5?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment4?.C5?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment4?.C5?.ioValues?.system_pressure?.id,
                    locationEquipment4?.C5?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              >
                <Statistic
                  float
                  floatColor={theme.pipeColors.air}
                  label={locationEquipment4?.Other?.ioValues[IOValueKeys.dischargeAirPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment4?.Other?.ioValues[IOValueKeys.dischargeAirPressure]?.value}
                />
                <Pipe color={theme.pipeColors.air} horizontal>
                  <Statistic
                    float
                    floatColor={theme.pipeColors.air}
                    label={locationEquipment4?.Other?.ioValues[IOValueKeys.dischargeAirFlow]?.ioValueType?.units}
                    border
                    statistic={locationEquipment4?.Other?.ioValues[IOValueKeys.dischargeAirFlow]?.value}
                  />
                </Pipe>
              </IRCompressor>
              <PipePlaceholder hidden />
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
    min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
    max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
    min-height: 100vh;
    max-height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
      min-width: calc(100vw - 16px);
      max-width: calc(100vw - 16px);
      margin-left: 16px;
    }
    transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
  `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

AnnexScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLC: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

AnnexScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLC })(AnnexScreen);
